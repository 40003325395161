$primary: #2C4C37;
$secondary: #F2Ae2E;
$tertiary: #F25C69;

$light_blue: #B3E0F2;

// Headings
$headings-color: $primary;
$heading-font-color: $primary;
$dark: $primary;

$base-font-color: #000000;
$link-color: $secondary;
$border-color: #dbdbdb;

$search-home-bg-color: $primary;
$advantage-color: #598527;
$positive-color: green;
$negative-color: red;
$star-reviews-color: #898989;
$mobile-header-background: white;
$mobile-header-color:$primary;

$legenda-available-color: #39b54a;
$legenda-blocked-color: #bbb;


$footer-background-color: $primary;
$bottom-footer-bg-color: $light_blue;
$bottom-footer-text-color: darken($light_blue, 60%) !important;

@import url('https://fonts.googleapis.com/css?family=Montserrat:200,400,700&display=swap');


$font-family-sans-serif: 'Montserrat', Arial, Helvetica, sans-serif;
$font-family-base: 'Montserrat';
$btn-font-family: 'Montserrat';
$headings-font-family: 'Montserrat';
$body-font-family: 'Montserrat';
$body-font-size: 1.1rem;
$body-line-height: 1.9rem;

@import "../../flean_base_2019/sass/imports";

#header {
  .mobile-header .container .logo-mobile.with-logo {
    flex: 1 1 auto;
  }
  .logo img {
    height: 60px !important;
    width: 390px !important;
    max-height: 60px !important;
    max-width: 390px !important;
    min-width: 390px !important;
    min-height: 60px !important;
  }

  .mobile-header .container .logo-mobile.with-logo a img {
      height: 48px !important;
      width: 299px !important;
      max-height: 48px !important;
      max-width: 240px !important;
      min-width: 240px !important;
      min-height: 48px !important;
  }
}

#bottom a {
  color: darken($light_blue, 60%) !important;
}



